import { Controller } from "@hotwired/stimulus"
// Refactored from drifting ruby
// Source = https://github.com/gorails-screencasts/google-places-maps-stimulus/blob/master/app/javascript/controllers/places_controller.js
// Connects to data-controller="places"
export default class extends Controller {
  static targets = ["field", "map", "lat", "lng", "full_name","formatted_address", "zip", "place_id", "geo_status", "distance"]
  connect() {
    console.log("places controller")
    if (typeof(google) != "undefined") {
      this.stimMap()
    }
  }
  stimMap(){
    console.log(google)
    this.map()
    
    this.marker()
    this.infowindow() //Call before autocomplete
    this.autocomplete()
  }
  infowindow(){
    console.log("this.infowindow")
    if(this._infowindow == undefined){
      console.log("this.infowindo is undefined")
      this._infowindow = new google.maps.InfoWindow({
        content: this.full_nameTarget.value
      })
      // Attach a click event listener to the marker
      this._marker.addListener('click', () => {
      // Open the info window and set its content
      this._infowindow.open(this.map(), this._marker)
      })
    }
    google.maps.event.trigger(this._marker, 'click')
    return this._infowindow
  }
  map() {
    console.log("...this.map?")
    if(this._map == undefined) {
      console.log("this.map is undefined.")
      this._map = new google.maps.Map(this.mapTarget, {
        center: new google.maps.LatLng(
          this.latTarget.value ,
          this.lngTarget.value 
        ),
        zoom: 14
        
      })
    }
    return this._map
  }
  marker() {
    if (this._marker == undefined) {
      console.log("this.marker is undefined")
      this._marker = new google.maps.Marker({
        map: this.map(),
        // anchorPoint: new google.maps.Point(0,0), fix #73
        icon: {
          path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
          strokeColor: 'red',
          scale: 5,
          rotation: 180
        },
      })
      let mapLocation = {
        lat: parseFloat(this.latTarget.value),
        lng: parseFloat(this.lngTarget.value)
      }
      this._marker.setPosition(mapLocation)
      this._marker.setVisible(true)
    }
    
    console.log("this.marker finished.")
    return this._marker
  }
  autocomplete() {
    console.log("this.autocomplete?")
    if (this._autocomplete == undefined) {
      console.log("...is undefined.")
      this._autocomplete = new google.maps.places.Autocomplete(this.fieldTarget)
      // Set restrictions here:
      // this.fieldTarget,{
      //   componentRestrictions: { country: ["us", "co"] },
      //   fields: ["address_components", "geometry"],
      //   types: ["address"],
      // })
      this._autocomplete.bindTo('bounds', this.map())
      this._autocomplete.setFields(['formatted_address', 'geometry' , 'name', 'address_components','place_id'])
      this._autocomplete.addListener('place_changed', this.placeChanged.bind(this))
    }
    console.log("this.autocomplete setup finished.")
    return this._autocomplete
  }

  placeChanged() {
    let place = this.autocomplete().getPlace()
    console.log("place =")
    console.log(place)
    
    if (!place.geometry) {
      // User entered the name of a Place that was not suggested and
      // pressed the Enter key, or the Place Details request failed.
      window.alert(`Address '${place.name}' can not be searched.Submit a valid address.`);
      this.geo_statusTarget.value = "noGeoPlace"
      return;
    } else {this.geo_statusTarget.value = "OK"}
    if (place && place.name) {
      // Update the content of the info window
      this.infowindow().setContent(place.name)
    }
    if (place.place_id) { 
     this.place_idTarget.value = place.place_id
    } 

    this.map().fitBounds(place.geometry.viewport)
    this.map().setCenter(place.geometry.location)
    this.marker().setPosition(place.geometry.location)
    this.marker().setVisible(true)
    
    
    this.latTarget.value = place.geometry.location.lat()
    this.lngTarget.value = place.geometry.location.lng()
    this.full_nameTarget.value = place.name
    // Call function for the zip
    this.parseGoogleResponse(place.address_components)
    // if still no zip
    if (this.zip == null) {
      this.geo_statusTarget.value = "noZip"
    // Call geocoder to fix #85 when places does not return the zip
        this.geocoder(place.geometry.location)
      }

   
    // Show the map
    console.log(`Show the map: '${this.map}' `);
    console.log(`Show the marker: '${this.marker}' `);
    // Render the map
    const newLocal = google.maps.event.trigger(this.map(), 'resize')
   
    // create a new event object and "click"
    var event = new Event('click');
    // infowindow.dispatchEvent(event);
  }
  parseGoogleResponse(components) {
    components.forEach(function(component) {
      component.types.forEach(function(type) {
        if (type === 'postal_code') {
          this.zipTarget.value = component.long_name;
          this.zip = component.long_name;
        }
      }, this);
    }, this);
  }

 keydown(event) {
    if (event.key == "Enter") {
      console.log("keydown, insepect DOM.")
 
      event.preventDefault()
    }
  }
  geocoder(latlng) {
    // Call the geocoder, set the formatted address
    console.log("geocoder(latlng)= " +latlng)
    // Find the :zip in the database
    // Return the answer
  }
}
